// @import '~bootswatch/dist/Cerulean/variables';

// /* import bootstrap to set changes */
// @import '~bootstrap/scss/bootstrap';
// @import '~bootswatch/dist/Cerulean/bootswatch';

.ql-toolbar.ql-snow {
  background-color: white;
}

.login {
  margin-top: 2rem;
  .logo {
    max-width: 100%;
  }
}

.header {
  .navbar {
    // background: url(img/barra_Encabezado_1920.jpg) center fixed;

    .back-button {
      cursor: pointer;
    }

    .back-button-empty {
      width: 2rem;
    }

    img.logo {
      height: 2.3rem;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
    }
  }
}

.app-ionicons {
  height: 2rem;
}

.app-menu {
  margin-top: 70px;

  .element {
    padding: 1rem 0;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone-file {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  display: flex;

  img {
    max-height: 10rem;
    max-width: 100%;
  }

  small {
    padding: 0.25rem;
    color: red;
    cursor: pointer;
  }
}

strong {
  font-weight: bold;
}

.rw-multiselect .rw-widget-container,
.rw-multiselect .rw-widget-container.rw-state-focus,
.rw-multiselect.rw-state-focus > .rw-widget-container,
.rw-multiselect .rw-widget-container.rw-state-focus:hover,
.rw-multiselect.rw-state-focus > .rw-widget-container:hover {
  background-color: inherit;
}

.img-loader-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: gray;
}

.img-loader {
  margin-left: 15%;
  border: 9px solid #f3f3f3;
  border-radius: 50%;
  border-top: 9px solid #3498db;
  width: 45px;
  height: 45px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.img-loader-text {
  margin: 5% 5%;
}

.loader-container {
  width: 100%;
  height: 100%;
  top: 35%;
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  z-index: 9;
}

.loader {
  border: 0.25em solid #bdbdbd;
  border-radius: 50%;
  border-top: 0.25em solid #3498db;
  width: 2rem;
  height: 2rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-text {
  font-size: 20px;
  // margin: -25px 0 0 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
